<template>
    <div class="page-not-found-wrapper">
        <h1 class="center">Sorry, we can't find that page or<br>something has gone wrong</h1>
    </div>
    
</template>

<script>
export default {
    name: 'PageNotFound'
}
</script>

<style scoped>
    .page-not-found-wrapper{position: relative;min-height: calc(100vh - 105px);text-align: center;}
    .page-not-found-wrapper h1{width: 90%;color: #9fa7b3;font-weight: 600;}
</style>